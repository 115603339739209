<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [completed]="!!targetSchool" [label]="'選擇學校' | i18next" state="phone">
    <mat-form-field class="mb-4 w-100">
      <mat-label>{{ '學校名稱' | i18next }}</mat-label>
      <input matInput [required]="true"
        type="text" [placeholder]="'輸入學校名稱關鍵字後，選擇學校' | i18next"
        [formControl]="schoolControl"
        [matAutocomplete]="auto">
      <i class="iconify" data-icon="mdi:magnify" data-inline="false" matSuffix></i>
      <mat-autocomplete #auto="matAutocomplete"
        [displayWith]="displayFn" (optionSelected)="selectedSchool($event.option.value)">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">
            {{option.SchoolName}}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <p class="text-danger">{{message}}</p>
    <div>
      <button mat-button (click)="checkSchool(stepper)">{{ '下一步' | i18next }}</button>
    </div>
  </mat-step>
  <mat-step [completed]="!!targetRole" [label]="'選擇身分' | i18next" state="role">
    <div class="w-100" idpage>
      <div class="d-flex align-items-center w-100 p-3">
        <div class="id-item" [ngClass]="{'active': targetRole === 'guest'}" (click)="selectedRole('guest', stepper)">
          <div class="id-item-icon" style="background:url(/assets/img/id_g.png);"></div>
          <div class="id-item-text">{{ '訪客' | i18next }}</div>
        </div>
        <div class="id-item" [ngClass]="{'active': targetRole === 'teacher'}" (click)="selectedRole('teacher', stepper)">
          <div class="id-item-icon" style="background:url(/assets/img/id_t.png);"></div>
          <div class="id-item-text">{{ '教師' | i18next }}</div>
        </div>
        <div class="id-item" [ngClass]="{'active': targetRole === 'student'}" (click)="selectedRole('student', stepper)">
          <div class="id-item-icon" style="background:url(/assets/img/id_s.png);"></div>
          <div class="id-item-text">{{ '學生' | i18next }}</div>
        </div>
        <div class="id-item" [ngClass]="{'active': targetRole === 'parent'}" (click)="selectedRole('parent', stepper)">
          <div class="id-item-icon" style="background:url(/assets/img/id_p.png);"></div>
          <div class="id-item-text">{{ '家長' | i18next }}</div>
        </div>
      </div>
    </div>
    <div>
      <button mat-button matStepperPrevious>{{ '上一步' | i18next }}</button>
      <button mat-button matStepperNext>{{ '下一步' | i18next }}</button>
    </div>
  </mat-step>
  <mat-step [completed]="joinFinish" [label]="'驗證代碼' | i18next" state="code">
    <form>
      <div class="w-100" idpage>
        @if (targetRole==='guest') {
          <p>{{ '請直接點選「驗證」' | i18next }}</p>
        }

        @if (targetRole==='teacher' && targetRoleExists.get('teacher')) {
          <p>{{ '系統偵測到您在「v1」已經具有身分，請直接點選「驗證」。' | i18next: { v1: targetSchool.SchoolName } }}</p>
        }
        @if (targetRole==='teacher' && !targetRoleExists.get('teacher')) {
          <mat-form-field class="mb-4 w-100">
            <mat-label>{{ '教師代碼' | i18next }}</mat-label>
            <input matInput [required]="true" autocomplete="off"
              class="form-control" type="text" [placeholder]="'請輸入教師代碼' | i18next"
              name="code" [(ngModel)]="targetCode">
            <mat-hint align="start"><strong>{{ '請詢問學校相關行政單位取得教師代碼。' | i18next }}</strong></mat-hint>
          </mat-form-field>
        }

        @if (targetRole==='student' && targetRoleExists.get('student')) {
          <p>{{ '系統偵測到您在「v1」已經具有身分，請直接點選「驗證」。' | i18next: { v1: targetSchool.SchoolName} }}</p>
        }
        @if (targetRole==='student' && !targetRoleExists.get('student')) {
          <mat-form-field class="mb-4 w-100">
            <mat-label>{{ '學生代碼' | i18next }}</mat-label>
            <input matInput [required]="true" autocomplete="off"
              class="form-control" type="text" placeholder="請輸入學生代碼"
              name="code" [(ngModel)]="targetCode">
            <mat-hint align="start"><strong>{{ '請詢問導師取得學生代碼。' | i18next }}</strong> </mat-hint>
          </mat-form-field>
        }

        @if (targetRole==='parent') {
          <mat-form-field class="mb-4 w-100">
            <mat-label>{{ '家長代碼' | i18next }}</mat-label>
            <input matInput [required]="true" autocomplete="off"
              class="form-control" type="text" [placeholder]="'請輸入家長代碼' | i18next"
              name="code" [(ngModel)]="targetCode">
            <mat-hint align="start"><strong>{{ '請詢問孩子或聯絡導師取得家長代碼。' | i18next }}</strong> </mat-hint>
          </mat-form-field>
          <mat-form-field class="mb-4 w-100">
            <mat-label>{{ '親子關係' | i18next }}</mat-label>
            <mat-select name="stuRelation" [(ngModel)]="targetRelation" [required]="true">
              <mat-option value="">{{ '選擇稱謂' | i18next }}</mat-option>
              <mat-option [value]="'父親' | i18next">{{ '父親' | i18next }}</mat-option>
              <mat-option [value]="'母親' | i18next">{{ '母親' | i18next }}</mat-option>
              <mat-option [value]="'祖父' | i18next">{{ '祖父' | i18next }}</mat-option>
              <mat-option [value]="'祖母' | i18next">{{ '祖母' | i18next }}</mat-option>
              <mat-option [value]="'外祖父' | i18next">{{ '外祖父' | i18next }}</mat-option>
              <mat-option [value]="'外祖母' | i18next">{{ '外祖母' | i18next }}</mat-option>
              <mat-option [value]="'其他' | i18next">{{ '其他' | i18next }}</mat-option>
            </mat-select>
          </mat-form-field>
        }
      </div>
      <p class="text-danger">{{message}}</p>
      @if (targetRole === 'parent' && lockState === 'LOCK') {
        <p class="text-danger">{{ '輸入代碼錯誤已達 v1 次，請於15分鐘後再進行代碼驗證。' | i18next: { v1: attemptStatus?.failed_attempts } }}</p>
        <p class="text-danger">{{ '鎖定倒數' | i18next }}：{{remainingTime}}</p>
      }
      <div>
        <button mat-button matStepperPrevious>{{ '上一步' | i18next }}</button>
        <button mat-button matStepperNext (click)="joinNewRole(stepper)" class="gtm-valid-code"
          [disabled]="(targetRole === 'parent' && lockState !== 'UNLOCK') || isSaving">
          <span [attr.data-gtm]="targetSchool?.DSNS + '__' + targetRole">{{ '驗證' | i18next }}</span></button>
        </div>
      </form>
    </mat-step>
    <mat-step [label]="'完成' | i18next">
      <p>{{message}}</p>
      <p>{{ '請點選「繼續」新增其他身分；或「完成」開始操作新身分' | i18next }}</p>
      <div>
        <button mat-button (click)="resetStepper(stepper)">{{ '繼續' | i18next }}</button>
        <button mat-button (click)="reLogin()">{{ '完成' | i18next }}</button>
      </div>
    </mat-step>
    <ng-template matStepperIcon="phone">
      <mat-icon>call_end</mat-icon>
    </ng-template>
  </mat-horizontal-stepper>
