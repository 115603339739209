import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private store: Store
  ) { }

  /** 儲存設定，data 必需是 json 物件。 */
  public setPreference(key: string, data: any) {
    try {
      localStorage.setItem(`${this.getUserID()}:${key}`, JSON.stringify(data));
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 取得設定值。
   */
  public getPreference<T>(key: string): T {
    try {
      const json = localStorage.getItem(`${this.getUserID()}:${key}`);
      return JSON.parse(json) as T;
    } catch (err) {
      console.log(err);
    }
  }

  public removePreference(key: string) {
    try {
      localStorage.removeItem(`${this.getUserID()}:${key}`);
    } catch (err) { console.log(err); }
  }

  private getUserID() {
    const user = this.store.selectSnapshot(UserState.authInfo);
    return user.account;
  }
}
