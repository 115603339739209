import { PlatformHeaderModule } from './platform-header/platform-header.module';
import { PlatformWeb3Module } from './platform-web3/platform-web3.module';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { ModalModule } from 'ngx-bootstrap/modal';
import { I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import Backend from 'i18next-http-backend';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SchoolRouteComponent } from './school-route.component';
import { environment } from '../environments/environment';
import { PageNotFoundModule } from './shared/page-not-found/page-not-found.module';
import { MatIconRegistry } from '@angular/material/icon';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DSAInterceptor } from './dsutil-ng/dsa_interceptor';
import { DSA_ACCESSTOKEN } from './dsutil-ng/credential_provider';
import { LoginService } from './platform-web3/login.service';
import { UserDSALinksService } from './platform-web3/user-dsa-links.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

export function userDSALinks(userDSALinksService: UserDSALinksService): () => Promise<void> {
  return () => userDSALinksService.init();
}
export const USER_DSA_LINKS_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: userDSALinks,
    deps: [UserDSALinksService],
    multi: true
  }
];

export function appInit(i18next: ITranslationService) {
  i18next.use(Backend);

  return () => i18next.init({
    load: 'currentOnly',
    supportedLngs: ['zh-TW', 'zh-CN', 'en'],
    fallbackLng: 'zh-TW',
    debug: false,
    returnEmptyString: false,
    ns: [
      'translation',
    ],
    backend: {
      loadPath: '/assets/locales/{{lng}}.json'
    }
  });
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SchoolRouteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PlatformHeaderModule,
    PlatformWeb3Module,
    BrowserAnimationsModule,
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    ModalModule.forRoot(),
    PageNotFoundModule,
    I18NextModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
  ],
  providers: [
    MatIconRegistry,
    { provide: HTTP_INTERCEPTORS, useClass: DSAInterceptor, multi: true },
    { provide: DSA_ACCESSTOKEN, useClass: LoginService },
    I18N_PROVIDERS,
    USER_DSA_LINKS_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    // matIconRegistry.setDefaultFontSetClass('mdi');
  }
}
