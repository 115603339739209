import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { JoinRoleComponent } from './join-role/join-role.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { JoinSchoolComponent } from './join-school/join-school.component';
import { DSUtilNgModule } from 'src/app/dsutil-ng/dsutil-ng.module';
import { I18NextModule } from 'angular-i18next';

@NgModule({
  declarations: [
    JoinRoleComponent,
    JoinSchoolComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DSUtilNgModule,
    MatStepperModule,
    MatIconModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    I18NextModule,
  ],
  exports: [
    JoinRoleComponent,
    JoinSchoolComponent,
  ],
})
export class RoleManagerModule { }
