import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AdminRecord, GuestRecord, ParentChildRecord, StudentRecord, TeacherRecord } from 'src/app/data';
import { PlatformUrlService } from 'src/app/platform-web3/platform-url.service';
import { UserRoleState } from 'src/app/platform-web3/user-role.state';
import { HeaderTitleService } from 'src/app/shared';
import { environment } from '../../../environments/environment';
import { GetSchoolMultiName } from 'src/app/platform-web3/user.action';

type RoleCombine = TeacherRecord & ParentChildRecord & StudentRecord & AdminRecord & GuestRecord;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  title = '';
  unSubscribe$ = new Subject();
  logoUrl: string = undefined;
  isProduction = environment.production;
  isOpenDrawer = true;
  homepageUrl: Observable<string>;

  constructor(
    private headerTitleSrv: HeaderTitleService,
    private browserTitle: Title,
    private nav: PlatformUrlService,
    private store: Store,
  ) { }

  async ngOnInit() {

    // 訂閱標題文字變化
    this.headerTitleSrv.headerTitle$.pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe((value) => {
      this.title = value || '';
      this.browserTitle.setTitle((value ? `${value} - ` : '') + environment.appName);
    });

    this.homepageUrl = this.nav.homePageUrl();
    
    // 處理當學校變更時，更新 logo。
    this.store.select(UserRoleState.selectedRole).pipe(
      map((role: RoleCombine) => {
        return role?.dsns;
      }),
      tap(v => {
        if (!!v) {
          this.store.dispatch(new GetSchoolMultiName(v));
        }
      })
    ).subscribe(v => {
      if (v) {
        const url = `/service/profile/logo?dsns=${v}`
        this.logoUrl = url;
      } else {
        this.logoUrl = undefined;
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
  }
}
