import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-content',
  template: `
<div class="p-4">
  <h2 class="mat-dialog-title" id="tPrivacyLabel">隱私權政策</h2>
  <mat-dialog-content>
  <div>
      <p>澔學學習股份有限公司ischool Inc. (以下簡稱「ischool」) ，感謝您使用我們的產品與服務（以下簡稱「本服務」），ischool 尊重使用者的個人隱私，並遵守個人資料保護法及其相關法律及規範，為了讓您能夠安心使用本服務的各項服務與資訊，特此向您說明本服務的隱私權保護政策，以保障您的權益，請您詳閱下列有關隱私權政策的更多內容：</p>
      <ol>
          <li>
              <h3>資料的蒐集與使用方式</h3>
              <div>
                  <ol type="a">
                      <li>在您註冊 ischool 帳號、使用「服務」、瀏覽 ischool 網頁或某些合作夥伴的網頁，以及參加宣傳活動或贈獎遊戲時，ischool 會蒐集您的個人資料（包括您的姓名、相片、身分證字號、手機號碼、電子郵件地址、出生日期、性別、學校資訊與代碼、郵遞區號、職位、行業及個人興趣等資料。）。ischool 也可能從商業夥伴或其他公司處取得您的個人資料，並將這些資料與 ischool 所擁有您的個人資料進行整合。</li>
                      <li>本服務也自動接收並記錄您電腦和瀏覽器上的資料，包括上述表列之個人資訊 IP 位址、ischool cookie 中的資料、軟體和硬體屬性以及您瀏覽的網頁紀錄，做為我們增進 APP 服務的參考依據，此記錄為 ischool 內部應用，決不對外公佈。</li>
                      <li>本服務將資料用作以下用途：客製化廣告及您看到的網頁內容、滿足您對產品和服務的要求、改進我們的服務、聯絡您、進行研究，以及提供內部及外部客戶匿名報告。</li>
                  </ol>
              </div>
          </li>
          <li>
              <h3>資料分享與揭露</h3>
              <div>本服務為了向您提供您所存取的服務、獲得您的資料授權，否則 ischool 絕不會出借或出售您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
                  <ol type="a">
                      <li>本服務向已簽署保密協議書之執行業務代表或與我們共事的值得信賴的合作夥伴提供這些資料。這些公司可能會將您的個人資料用於協助 ischool 就我們和行銷夥伴所提供產品及服務的事宜，與您進行溝通。但這些公司無獨立權利分享這些資料。</li>
                      <li>本服務對傳票、法院命令或法律程序作出回應、取得或行使法律權利，或對訴訟上之請求提出防禦。</li>
                      <li>本服務為了配合調查和防止非法活動、涉嫌詐欺、對人身安全有潛在威脅的狀況、對 ischool 服務條款的違反，或可能損害或妨礙APP服務與其他使用者權益或導致他人遭受損害時，經揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                      <li>如果 ischool 被其他公司收購或合併，我們會將您的個人資料移轉給該公司。如果發生這種情況，ischool 會在您的個人資料被移轉且將適用不同的隱私權政策前通知您。</li>
                      <li>本服務會根據您的個人資料向您展示精準式行銷廣告。廣告主（包括廣告遞送公司）可能假定那些瀏覽、點閱精準式行銷廣告或與廣告互動的人士符合精準式行銷標準， 在您瀏覽精準式行銷廣告或與廣告發生互動時，本服務不會主動提供您的任何個人資料。然而，一旦瀏覽廣告或與廣告發生互動，即表示您同意廣告主可能會假定您符合展示型廣告所使用的精準式行銷標準。</li>
                  </ol>
              </div>
          </li>
          <li>
              <h3>Cookie</h3>
              <div>
                  <ol type="a">
                      <li>本服務會在您的電腦上設定並存取 ischool cookie。</li>
                      <li>本服務允許在某些 ischool 網頁上刊登廣告的其他公司，在您的電腦上設定並存取 cookie。其他公司將根據其自訂的隱私權政策使用其 cookie，並非依據本政策。</li>
                      <li>第三方服務或其他公司不能存取本服務的 cookie。</li>
                      <li>本服務會存取我們的網站內外與產品及服務有關的 cookie。</li>
                  </ol>
              </div>
          </li>
          <li>
              <h3>機密性和安全性</h3>
              <p>本服務將有權存取您個人資料之員工，限於我們合理相信是為了向您提供產品或服務或為了完成工作而需要取得這些資料的員工。我們已採取符合法規要求的實體、電子和程序防護措施，以保護您個人資料的安全。</p>
          </li>
          <li>
              <h3>您修改及刪除個人帳號資料的能力</h3>
              <p>使用者可隨時修改個人 ischool 帳號資料。我們保有權利向您傳送 ischool 服務相關的特定訊息（如服務公告、管理訊息），這些訊息被視為您 ischool 帳號的一部分，您無法選擇退出接收。如果使用者要刪除您的 ischool 帳號，請與我們聯繫。</p>
          </li>
          <li>
              <h3>行動裝置資訊存取</h3>
              <p>為了提供更完善的行動裝置服務，我們將會取用您行動裝置之相機、麥克風及相簿的存取權限，相機將作為掃描 QRCode 識別身分使用，您隨時可修改於手機中對本應用程式的設定。</p>
          </li>
          <li>
              <h3>隱私權保護政策修訂</h3>
              <p>本服務將因應需求不時修訂本政策。當我們在個人資料的處理上有重大變更時，會將通知傳送到您在 ischool 帳號中指定的主要電子郵件地址，或在我們的網站上張貼告示。</p>
          </li>
          <li>
              <h3>問題和建議</h3>
              <p>如果您有任何問題或建議，請透過
                  <a href="mailto:support@ischool.com.tw">客服信箱</a>
                  與我們聯繫。</p>
          </li>
      </ol>
  </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>關閉</button>
  </mat-dialog-actions>
</div>
  `
})
export class PrivacyContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
