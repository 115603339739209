import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AdminRecord, DSARoleRecord, GuestRecord, ParentChildRecord, SchoolLinkRecord, StudentRecord, TeacherRecord } from '../data/index';
import { RoleRecord } from '../data/role_base';
import { GetAccessToken } from './auth.action';
import { AuthService } from './auth.service';
import { AuthState } from './auth.state';
import { RemoveSchoolRoles, RetrieveSchoolRoles } from './user-role.actions';
import { SetSchoolRoleRetrieved } from './user.action';
import { Role, RoleWithID, UserState } from './user.state';

export class UserRoleStateModel extends Array<RoleRecord> {}

export const USER_ROLE_STATE_TOKEN = new StateToken<UserRoleStateModel>('identity');

export type GetRoleById = (dsns: string, role: Role, id: number) => RoleRecord;

@State<UserRoleStateModel>({
  name: USER_ROLE_STATE_TOKEN,
  defaults: []
})
@Injectable()
export class UserRoleState {

  constructor(
    private authSrv: AuthService,
    private store: Store
  ) {}

  @Selector()
  static getRoles(state: UserRoleStateModel) {
    return state;
  }

  @Selector()
  static getSchoolRoles(state: UserRoleStateModel) {
    return (dsns: string) => {
      return state.filter(v => v.dsns === dsns);
    };
  }

  /** 取得特定角色的資料。 */
  @Selector()
  static getRoleById(state: UserRoleStateModel): GetRoleById {
    return (dsns: string, role: Role, id: number) => {
      return state.find(r => {
        return r.dsns === dsns && r.roleName === role && r.id === id;
      });
    };
  }

  /** 取得目前使用者選擇的 Role 資料。 */
  @Selector([UserState.selectedRole, UserRoleState.getRoleById])
  static selectedRole(
    state: UserState,
    role: RoleWithID,
    getRoleById: GetRoleById
  ) {
    return getRoleById(role.dsns, role.role, role.id);
  }

  @Action(RemoveSchoolRoles)
  removeSchoolRoles(ctx: StateContext<UserRoleStateModel>, action: RemoveSchoolRoles) {
    const { dsns } = action;

    const rest = ctx.getState().filter(r => r.dsns != dsns);
    ctx.setState(rest);
  }

  /** 找回學校中的角色 */
  @Action(RetrieveSchoolRoles)
  retriveveSchoolRoles(ctx: StateContext<UserRoleStateModel>, action: RetrieveSchoolRoles) {
    const { dsns, force } = action;

    // 尋找指定 dsns 的 DSALinkRecord.
    const linkRecord = this.store.selectOnce(UserState.getSchoolLink)
      .pipe(map(fn => fn(dsns)));

    const accessToken = this.store.selectOnce(AuthState.accessToken);

    // 先取得最新的 access token。
    return this.store.dispatch(new GetAccessToken()).pipe(
      withLatestFrom(accessToken, linkRecord),
      mergeMap(([_, token, lnkRecord]) => {
        if (!lnkRecord.roles_retrieved || force) { // 如果還沒有下載角色或是強制下載。
          return this.downloadSchoolRoles(ctx, token, lnkRecord);
        } else {
          return of([] as Array<DSARoleRecord>);
        }
      })
    );
  }

  private downloadSchoolRoles(
    ctx: StateContext<UserRoleStateModel>,
    token: string,
    lnkRecord: SchoolLinkRecord
  ) {

    const dsns = lnkRecord.ap_name;
    return this.authSrv.getDSARole(dsns, token).pipe(
      tap({
        next: (roles) => {
          let newState = [ ...ctx.getState() ];
          newState = this.cleanSingleSchoolRoles(dsns, newState);
          this.fillIntoState([].concat(roles || []), newState, lnkRecord);
          ctx.setState(newState);
        }
      }),
      tap(() => this.store.dispatch(new SetSchoolRoleRetrieved(dsns)))
    );
  }

  private fillIntoState(roles: DSARoleRecord[], newState: UserRoleStateModel, dsalink: SchoolLinkRecord) {
    for (const role of roles) {
      switch (role.Role) {
        case 'teacher':
          newState.push(TeacherRecord.create({
            id: role.ID,
            name: role.Name,
            schoolName: dsalink.name,
            dsns: dsalink.ap_name,
            attributes: { visibility: role.Attributes.Visibility }
          }));

          break;
        case 'student':
          newState.push(StudentRecord.create({
            id: role.ID,
            name: role.Name,
            className: role.ClassName,
            parentCode: '',
            schoolName: dsalink.name,
            dsns: dsalink.ap_name,
            attributes: { visibility: role.Attributes.Visibility }
          }));
          break;
        case 'parent':
          newState.push(
            ParentChildRecord.create({
              id: role.ID,
              studentId: role.ID,
              name: role.Name,
              schoolName: dsalink.name,
              className: role.ClassName,
              dsns: dsalink.ap_name,
              attributes: { visibility: role.Attributes.Visibility }
            }));
          break;
        case 'admin':
          // 學校管理員
          newState.push(AdminRecord.create({
            id: role.ID,
            name: role.Name,
            schoolName: dsalink.name,
            dsns: dsalink.ap_name,
            attributes: { visibility: role.Attributes.Visibility }
          }));
          break;
      }
    }

    if (!roles.length) { // roles 是 空陣列 就是在那個學校沒有角色。
      newState.push(
        GuestRecord.create({
          id: -1, // 指定為 -1
          name: this.store.selectSnapshot(UserState.authInfo).name,
          schoolName: dsalink.name,
          dsns: dsalink.ap_name,
        })
      );
    }
  }

  // 清除指定 dsns 的所有角色。
  private cleanSingleSchoolRoles(dsns: string, state: UserRoleStateModel) {
    return state.filter(v => v.dsns !== dsns);
  }
}
