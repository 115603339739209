import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from './auth.state';

@Injectable({
  providedIn: 'root'
})
export class AddSchoolDetectionGuard  {
  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const { store, router } = this;
    const dsns = route.queryParamMap.get('school');

    if (dsns) {
      const token = store.selectSnapshot(AuthState.accessToken);
      const result = await this.addGreeningApp(dsns, token);
      if(result.info == 'success') {
        const target = `/auth/redirect_ischool_oauth?next=/s/${dsns}`
        window.location.assign(target);
        return false;
      }
    }

    return true;
  }


  /**於 greening 加入關聯的學校清單 */
  private async addGreeningApp(appName: string, accessToken: string) {

    const content = encodeURIComponent([
      `<Request>`,
      `<Applications>`,
      `<Application>`,
      `<AccessPoint>${appName}</AccessPoint>`,
      `<Type>dynpkg</Type>`,
      `</Application>`,
      `</Applications>`,
      `</Request>`
    ].join(''));

    const url = [
      `https://greening.ischool.com.tw/dsa/greening/user/AddApplicationRef`,
      `?stt=passportaccesstoken`,
      `&AccessToken=${accessToken}`,
      `&content=${content}`,
      `&rsptype=json`
    ].join('');

    const greeningRsp: any = await this.http.get(url).toPromise();

    if (greeningRsp.Header && greeningRsp.Header.Status && greeningRsp.Header.Status.Message) {
      if (greeningRsp.Header.Status.Message.indexOf('account_app_ref_account_id_access_point_type_key') !== -1) {
        return { info: 'success' };
      } else {
        return { info: 'faild' };
      }
    } else {
      return { info: 'success' };
    }
  }

}
