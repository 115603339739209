import { Component, OnInit } from '@angular/core';
import { RoleUIService } from '../role-ui.service';

@Component({
  selector: 'app-join-school',
  templateUrl: './join-school.component.html',
  styleUrls: ['./join-school.component.scss']
})
export class JoinSchoolComponent implements OnInit {

  constructor(
    private role: RoleUIService
  ) { }

  ngOnInit(): void {
    this.role.openJoinRole();
  }

}
