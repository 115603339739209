import { Component } from '@angular/core';
import { AuthService } from 'src/app/platform-web3/auth.service';

@Component({
  selector: 'app-logout',
  template: '',  // 沒有需要顯示的模板
})
export class LogoutComponent {
  constructor(private authService: AuthService) {
    this.authService.logout();  // 執行登出邏輯
  }
}
