import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header-bar/header.component';
import { AccountMenuComponent } from './role-selector/role-selector.component';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { PipesModule, SnackbarModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountSettingComponent } from './account-setting/account-setting.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { ServiceContentComponent } from './header-bar/terms/service-content.component';
import { PrivacyContentComponent } from './header-bar/terms/privacy-content.component';
import { CopyrightContentComponent } from './header-bar/terms/copyright-content.component';
import { PlatformWeb3Module } from '../platform-web3/platform-web3.module';
import { RoleManagerModule } from '../shared/role-manager/role-manager.module';
import { I18NextModule } from 'angular-i18next';
import { RoleFormatPipe } from '../shared/pipes/role-format.pipe';
import { AuthQRCodeComponent } from './auth-qrcode/auth-qrcode.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    HeaderComponent,
    AccountMenuComponent,
    AccountSettingComponent,
    ServiceContentComponent,
    PrivacyContentComponent,
    CopyrightContentComponent,
    AuthQRCodeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    PipesModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    SnackbarModule,
    MatStepperModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatRadioModule,
    MatListModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    PlatformWeb3Module,
    RoleManagerModule,
    I18NextModule,
  ],
  exports: [
    HeaderComponent,
  ],
  providers: [
    RoleFormatPipe,
  ]
})
export class PlatformHeaderModule { }
