import { TeacherRecord, StudentRecord, ParentChildRecord } from '../data/index';

export class AddTeacherIdentity {
  static readonly type = '[identity] add teacher';
  constructor() {}
}

export class RemoveTeacherIdentity {
  static readonly type = '[identity] remove teacher';
  constructor(public id: number) {}
}

export class AddStudentIdentity {
  static readonly type = '[identity] add student';
  constructor() {}
}

export class RemoveStudentIdentity {
  static readonly type = '[identity] remove student';
  constructor(public id: number) {}
}

export class AddParentIdentity {
  static readonly type = '[identity] add parent';
  constructor(public relationship: string, public code: string) {}
}

export class RemoveChild {
  static readonly type = '[identity] remove child';
  constructor(public id: number) {}
}

export class AppendTeacher {
  static readonly type = '[identity] append teacher';
  constructor(public teacher: TeacherRecord) {}
}

export class AppendStudent {
  static readonly type = '[identity] append student';
  constructor(public student: StudentRecord) {}
}

export class AppendParent {
  static readonly type = '[identity] append parent';
  constructor(public xparent: ParentChildRecord) {}
}

/** 呼叫 DSA Service 確認使用者在學校的角色資料。 */
export class RetrieveSchoolRoles {
  static readonly type = '[identity] confirm school roles';
  constructor(public dsns: string, public force: boolean = false) {}
}

export class RemoveSchoolRoles {
  static readonly type = '[identity] remove school roles';
  constructor(public dsns: string) {}
}

export class AddGuestIdentity {
  static readonly type = '[identity] add guest';
  constructor() {}
}
