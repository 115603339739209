import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { AccessTokenCredential } from '../dsutil-ng/credential_provider';
import { GetAccessToken } from './auth.action';
import { AuthState } from './auth.state';
import { LinkService } from './link.service';

/** 處理目前登入狀態服務，大部份時後你應該用這個。 */
@Injectable({
  providedIn: 'root'
})
export class LoginService implements AccessTokenCredential {

  constructor(
    private store: Store,
    private link: LinkService
  ) { }

  /** 取得一個有期限內的 AccessToken。 */
  public getAccessToken(force: boolean = false) {
    const { store } = this;

    return store.dispatch(new GetAccessToken(force)).pipe(
      withLatestFrom(store.select(AuthState.accessToken)),
      map(([_, token]) => token),
    );
  }

  /** 取得穩定登入 auth 的第三方連結。 */
  public getLinkout(target: string) {
    // 確保 access token 在最新狀態。
    return this.link.getLinkout(target);
  }

  // AccessTokenCredential impl.
  getCredential(): Observable<string> {
    return this.getAccessToken();
  }

}
