import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ConfirmDialogOptions, ModalSize } from './confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private dialog: MatDialog,
    @Inject(I18NEXT_SERVICE) private i18nextSrv: ITranslationService,
  ) { }

  show(options: ConfirmDialogOptions) {
    options = {
      message: this.i18nextSrv.t('您確定嗎？').toString(),
      header: '',
      acceptLabel: this.i18nextSrv.t('確定').toString(),
      rejectLabel: this.i18nextSrv.t('取消').toString(),
      accept: (ref) => this.dialogRef.close(),
      reject: (ref) => this.dialogRef.close(),
      acceptButtonStyleClass: 'btn btn-info',
      rejectButtonStyleClass: 'btn btn-light text-black-secondary',
      acceptVisible: true,
      rejectVisible: true,
      modalSize: ModalSize.MD,
      ...options
    };

    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: options,
      maxWidth: options.modalSize,
    });

    return this.dialogRef.afterClosed();
  }

  hide() {
    this.dialogRef.close();
  }
}
