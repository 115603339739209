import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { Observable, of } from 'rxjs';
import { Jsonx } from '../dsutil-ng/jsonx';

@Injectable({
  providedIn: 'root'
})
export class SchoolListService {

  private privateBase = '';

  constructor(
    private http: HttpClient,
    private config: ConfigService,
  ) {
    this.privateBase = this.config.DSNS_HOST;
  }

  /** 取得全部 1Campus School 清單 */
  get1CampusSchoolList(q: string): Observable<OneCampusSchoolRecord[]> {
    let url = `${this.privateBase}/campusman.ischool.com.tw/config.public/Get1CampusSchoolList`;
    url += `?rsptype=json&content=<Match1><Match>${q}</Match></Match1>`;
    return this.http.get(url).pipe(
      map((v: any) => [].concat(v.Response.School || []) as OneCampusSchoolRecord[])
    );
  }

  /** 取得全部 1Campus School 清單 */
  searchSchool(q: string): Observable<OneCampusSchoolRecord[]> {
    const { http } = this;

    let url = `${this.privateBase}/campusman.ischool.com.tw?noredirect`;

    // 正確寫法，但未最佳化。
    // const ap = await AccessPoint.resolve('campusman.ischool.com.tw', 'config.public');
    // const conn = new Connection(ap, new PublicSecurityToken())
    // await conn.connect();
    // const rsp = await conn.send('SearchSchool', `<Keyword>${q}</Keyword>`);

    return http.get(url, { responseType: 'text' }).pipe(
      switchMap(dataurl => {
        return http.post(dataurl, `
        <Envelope>
          <Header>
            <TargetService>SearchSchool</TargetService>
            <TargetContract>config.public</TargetContract>
            <SecurityToken Type="Public">
            </SecurityToken>
          </Header>
          <Body>
            <Keyword>${q}</Keyword>
          </Body>
        </Envelope>`, {responseType: 'text'});
      }),
      map(v => {
        try {
          const rsp = Jsonx.parse(v);
          const records: OneCampusSchoolRecord[] = [];
          for(const school of rsp.child('Envelope', 'Body', 'Response').children('School')) {
            const Type = school.child('Type').text;
            const DSNS = school.child('DSNS').text;
            const SchoolName = school.child('SchoolName').text;
            const County = school.child('County').text;
            const CoreSystem = school.child('CoreSystem').text;
            records.push({ Type, DSNS, SchoolName, County, CoreSystem });
          }
          return records;
        } catch { [] as OneCampusSchoolRecord[]; }
      })
    );
  }
}

export interface OneCampusSchoolRecord {
  Type: string;
  DSNS: string;
  SchoolName: string;
  County: string;
  CoreSystem: string;
}
