<button mat-flat-button class="rounded-pill btn-nav-gray" [matMenuTriggerFor]="navIdentity" aria-label="menu" [attr.matTooltip]="'學校與身分切換' | i18nextEager">
  <span [innerHtml]="role.roleName === 'parent' ? role.name + '&nbsp;' : ''"></span>
  {{ role.roleName ? (role.roleName | roleFormat) : ('尚未選擇' | i18nextEager) }}
  <i class="iconify" data-icon="mdi:menu-down" data-inline="true"></i>
</button>
<mat-menu #navIdentity="matMenu" [hasBackdrop]="true" [backdropClass]="'cdk-overlay-dark-backdrop'" class="nav-account-menu-panel">
  <div class="header text-secondary">{{ '請選擇身分' | i18nextEager }}</div>
  <mat-divider class="my-2"></mat-divider>
  @for (school of mapSchoolRoles | keyvalue; track school) {
    <div class="identity">
      <p mat-list-item class="sub-header">{{school.value[0]?.schoolName}}</p>
      @for (item of school.value; track item) {
        <div mat-list-item class="d-flex align-items-center identity-card"
          [ngClass]="{active: isSelected(item)}"
          (click)="setCurrentIdentity(item, item.roleName)">
          <span class="role-icon">
            @if (item.roleName==='parent') {
              <span class="iconify" data-icon="ri:parent-fill" data-inline="true"></span>
            }
            @if (item.roleName==='teacher') {
              <span class="iconify" data-icon="fa-solid:chalkboard-teacher" data-inline="true"></span>
            }
            @if (item.roleName==='student') {
              <span class="iconify" data-icon="whh:student" data-inline="true"></span>
            }
            @if (item.roleName==='guest') {
              <span class="iconify" data-icon="fluent:slide-search-24-filled" data-inline="true"></span>
            }
            @if (item.roleName==='admin') {
              <span class="iconify" data-icon="feather:tool" data-inline="true"></span>
            }
          </span>
          <span class="ml-3" [ngClass]="{checked: isSelected(item)}"></span>
          <div>
            <div class="role-title" [innerHtml]="formatRoleData(item)"></div>
          </div>
        </div>
      }
    </div>
    <mat-divider class="my-2"></mat-divider>
  }
  <button mat-menu-item class="d-flex align-items-center gtm-click-join-role" (click)="openJoinModal()">
    <i class="iconify mr-3" data-icon="mdi:account-plus-outline" data-inline="true"></i>
    <span>{{ '新增身分' | i18nextEager }}</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item class="d-flex align-items-center gtm-click-manage-role" (click)="manageRole()">
    <i class="iconify  mr-3" data-icon="mdi:clipboard-account" data-inline="true"></i>
    <span>{{ '管理身分' | i18nextEager }}</span>
  </button>
</mat-menu>

