import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LoadUserSchoolLinks } from './user.action';

@Injectable({
  providedIn: 'root'
})
export class UserDSALinksService {

  constructor(private store: Store) {}

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.store.dispatch(new LoadUserSchoolLinks()).subscribe(() => {
        resolve();
      }, (error) => {
        resolve();
      });
    });
  }
}
