import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';
import { AddSchoolDetectionGuard } from './platform-web3/add-school.guard';
import { AuthGuardService } from './platform-web3/auth-guard.service';
import { DSALinksGuard } from './platform-web3/dsa-links.guard';
import { SchoolRouteComponent } from './school-route.component';
import { PageNotFoundComponent } from './shared';
import { JoinSchoolComponent } from './shared/role-manager/join-school/join-school.component';
import { LogoutComponent } from './logout.component';

const rootGuards = [AuthGuardService, DSALinksGuard];

const routes: Routes = [
  {
    path: 's',
    canActivate: [...rootGuards],
    component: SchoolRouteComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./school/school.module').then(m => m.SchoolModule),
        canActivate: [AddSchoolDetectionGuard]
      }
    ]
  },
  {
    path: 'embed',
    outlet: 'mobile',
    children: [
      {
        path: '',
        loadChildren: () => import('./school/mobile-embed/embed.module').then(m => m.EmbedModule),
      }
    ]
  },
  {
    path: 'personal-setting',
    canActivate: rootGuards,
    loadChildren: () => import('./personal-setting/personal-setting.module').then(m => m.PersonalSettingModule),
  },
  { path: 'pnf', component: PageNotFoundComponent},
  { path: 'join', component: JoinSchoolComponent, canActivate: [AuthGuardService]},
  {
    path: 'gadget_dev',
    loadChildren: () => import('./platform-dev/platform-dev.module').then(m => m.PlatformDevModule),
    canActivate: [AuthGuardService],

  },
  { path: 'logout', component: LogoutComponent },
  { path: '', redirectTo: 's', pathMatch: 'full' },
  { path: '**', redirectTo: 'pnf', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
