<button mat-flat-button class="rounded-pill btn-nav-gray" [matMenuTriggerFor]="navAuthInfo">
  <img class="user-photo mr-2" [src]="authInfo.profile">
  {{authInfo.name}}
</button>
<mat-menu #navAuthInfo="matMenu" class="nav-personal-menu-panel">
  <div class="authInfo-header">
    <img [src]="authInfo.profile">
    <div class="ml-3 text-left">
      <h1>{{authInfo.name}}</h1>
      <p>{{authInfo.account}}</p>
    </div>
  </div>
  @if (advanceUser) {
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item class="font-weight-normal" routerLink="/gadget_dev" style="color: red;">
      開發者設定
    </button>
    <!-- <button *ngIf="powerBackendUser" mat-menu-item routerLink="/backend" class="font-weight-normal" style="color: red;">
    高階系統診斷
  </button> -->
}
<mat-divider class="my-2"></mat-divider>
<button mat-menu-item class="font-weight-normal" (click)="openQRModal()">
  {{ '產生行動條碼' | i18nextEager }}
</button>
<button mat-menu-item class="font-weight-normal" [routerLink]="['/personal-setting', 'info']">
  {{ '管理你的 1Campus 帳號' | i18nextEager }}
</button>
@if (authInfo.isDomainAdmin) {
  <button mat-menu-item class="font-weight-normal" (click)="btnDomainManager()">
    {{ '管理網域' | i18nextEager }}
  </button>
}
<mat-divider class="my-2"></mat-divider>
<p class="sub-header"><span class="badge badge-danger">New</span> {{ '下載 1Campus Next App' | i18nextEager }}</p>
<button mat-menu-item class="font-weight-normal"
  (click)="openModal(tplQRIOSNext, { width: '280px', autoFocus: false })">
  App Store (iOS)
</button>
<button mat-menu-item class="font-weight-normal"
  (click)="openModal(tplQRAndroidNext, { width: '280px', autoFocus: false })">
  Google Play (Android)
</button>
<mat-divider class="my-2"></mat-divider>
<button mat-menu-item class="font-weight-normal" (click)="logout()">
  {{ '登出' | i18nextEager }}
</button>
<mat-divider class="my-2"></mat-divider>
<div class="min-link">
  <div>
    <a href="https://ischool.zendesk.com/hc/zh-tw" target="_blank" rel="noreferrer noopener">{{ '客戶服務' | i18nextEager
    }}</a>
    <small class="px-1">·</small>
    <a href="javascript:;" (click)="openServiceContent()">{{ '服務條款' | i18nextEager }}</a>
    <small class="px-1">·</small>
    <a href="javascript:;" (click)="openPrivacyContent()">{{ '隱私權政策' | i18nextEager }}</a>
    <small class="px-1">·</small>
    <a href="javascript:;" (click)="openCopyRightContent()">{{ '著作權政策' | i18nextEager }}</a>
  </div>
  <a href="https://legacy-web2.ischool.com.tw" target="_blank" rel="noreferrer noopener">web2</a>
  <small class="px-1">·</small>
  <a href="https://www.ischool.com.tw" target="_blank" rel="noreferrer noopener">Power by ischool Inc.</a>
</div>
</mat-menu>

<!-- Modal - APP STORE -->
<ng-template #tplQRIOS>
  <div>
    <h2 class="mat-dialog-title text-center" id="qrIOSLabel">APP STORE</h2>
    <mat-dialog-content>
      <img src="/assets/img/qr_ios.svg" class="w-100 mb-4">
      <a href="https://itunes.apple.com/app/1campus/id1273657906" target="_blank"
        rel="noreferrer noopener"
        class="w-100 rounded-pill"
      mat-flat-button color="primary">{{ '前往下載' | i18next }}</a>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>

<!-- Modal - Google play -->
<ng-template #tplQRAndroid>
  <div>
    <h2 class="mat-dialog-title text-center" id="qrAndroidLabel">Google play</h2>
    <mat-dialog-content>
      <img src="/assets/img/qr_android.svg" class="w-100 mb-4">
      <a href="https://play.google.com/store/apps/details?id=tw.com.ischool.onecampusmobile" target="_blank"
        rel="noreferrer noopener"
        class="w-100 rounded-pill"
      mat-flat-button color="primary">{{ '前往下載' | i18next }}</a>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>


<!-- Modal - APP STORE -->
<ng-template #tplQRIOSNext>
  <div>
    <h2 class="mat-dialog-title text-center" id="qrIOSNextLabel">APP STORE</h2>
    <mat-dialog-content>
      <img src="/assets/img/qr_next.png" class="w-100 mb-4">
      <a href="https://apps.apple.com/us/app/1campus-next/id6445990073" target="_blank"
        rel="noreferrer noopener"
        class="w-100 rounded-pill"
      mat-flat-button color="primary">{{ '前往下載' | i18next }}</a>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>

<!-- Modal - Google play -->
<ng-template #tplQRAndroidNext>
  <div>
    <h2 class="mat-dialog-title text-center" id="qrAndroidNextLabel">Google play</h2>
    <mat-dialog-content>
      <img src="/assets/img/qr_next.png" class="w-100 mb-4">
      <a href="https://play.google.com/store/apps/details?id=net.onecampus.app&hl=zh_TW" target="_blank"
        rel="noreferrer noopener"
        class="w-100 rounded-pill"
      mat-flat-button color="primary">{{ '前往下載' | i18next }}</a>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ '關閉' | i18next }}</button>
    </mat-dialog-actions>
  </div>
</ng-template>
