
export class GetAccessToken {
  static readonly type = '[auth] get access token';

  /**
   * 取得 AccessToken(會自動判斷是否需要再次向 Server 取得，8分鐘內的 Token 不會重新取得)。
   * @param force 是否強制取得。
   */
  constructor(public force: boolean = false) {}
}
