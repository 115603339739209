import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { RoleWithID, UserState } from './user.state';
import { UserRoleState } from './user-role.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlatformUrlService {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  /** 代表應用程式是在行動模式下運行。 */
  public get mobileMode() {
    const powerquery = location.search.search('mobile=true');
    if (powerquery >= 0) { return true }

    return false;
  }

  /** buildin gadget => 服務設定 */
  public goManageService() {

    const { router, store } = this;

    const link = store.selectSnapshot(UserState.selectedSchool);
    const cmds = this.schoolCommand(link.ap_name);
    cmds.push('m-service');
    const target = router.createUrlTree(cmds);

    return this.router.navigateByUrl(target);
  }

  /** buildin gadget => 分權設定 */
  public goGadgetVisibility() {
    const { router, store } = this;

    const link = store.selectSnapshot(UserState.selectedSchool);
    const cmds = this.schoolCommand(link.ap_name);
    cmds.push('gadget-visibility');
    const target = router.createUrlTree(cmds);

    return this.router.navigateByUrl(target);
  }

  /** web2 proxy 架構 */
  public goGadgetProxy(gadgetId: string) {
    const { router, store } = this;

    const role = store.selectSnapshot(UserRoleState.selectedRole);
    const cmds = this.schoolCommand(role.dsns, { dsns: role.dsns, role: role.roleName, id: role.id });
    cmds.push('g', 'proxy');

    const ut = router.createUrlTree([...cmds, gadgetId]);

    this.router.navigateByUrl(ut);
  }

  /** web2 remote agent 架構 */
  public goGadgetRemoteAgent(gadgetId: string) {
    const { router, store } = this;

    const role = store.selectSnapshot(UserRoleState.selectedRole);
    const cmds = this.schoolCommand(role.dsns, { dsns: role.dsns, role: role.roleName, id: role.id });
    cmds.push('g', 'agent');

    const ut = router.createUrlTree([...cmds, gadgetId]);

    this.router.navigateByUrl(ut);
  }

  /** buildin 架構(目前只剩 google 雲端設定使用) */
  public goGadgetWeb3Builtin(gadgetId: string) {
    const { router, store } = this;

    const role = store.selectSnapshot(UserRoleState.selectedRole);
    const cmds = this.schoolCommand(role.dsns, { dsns: role.dsns, role: role.roleName, id: role.id });
    cmds.push('g', 'builtin');

    const ut = router.createUrlTree([...cmds, gadgetId]);

    this.router.navigateByUrl(ut);
  }

  public goGadgetCore(gadgetId: string) {
    const { router, store } = this;

    const role = store.selectSnapshot(UserRoleState.selectedRole);
    const cmds = this.schoolCommand(role.dsns, { dsns: role.dsns, role: role.roleName, id: role.id });
    cmds.push('g', 'core');

    const ut = router.createUrlTree([...cmds, gadgetId]);

    this.router.navigateByUrl(ut);
  }

  /** 導行到指定學校、角色。 */
  public goSchool(dsns: string, role?: RoleWithID) {
    const { router } = this;
    return router.navigateByUrl(this.school(dsns, role));
  }

  /** 取得指定學校、角色的 UrlTree。 */
  public school(dsns: string, role?: RoleWithID) {
    const commands = this.schoolCommand(dsns, role);
    return this.router.createUrlTree(commands);
  }

  private schoolCommand(dsns: string, role?: RoleWithID) {
    const encdsns = encodeURIComponent(dsns);
    const commands = ['/s', encdsns];
    if (role) {
      commands.push('r', role.role, role.id + '');
    }
    return commands;
  }

  /** 到「加入學校(/join)」頁面。 */
  public joinSchool() {
    return this.router.createUrlTree(['/join']);
  }

  /** 取得 /pnf UrlTree */
  public pageNotFound() {
    return this.router.createUrlTree(['/pnf']);
  }

  /** 加入學校 */
  public addSchool(dsns: string) {
    return this.router.createUrlTree(['/'], { queryParams: { school: dsns } });
  }

  /** 取得目前選擇角色的 home page url (gadget list 畫面)。 */
  public homePageUrl(): Observable<string> {
    return this.store.select(UserRoleState.selectedRole).pipe(
      map(role => {
        if (role) {
          return `/s/${role.dsns}/r/${role.roleName}/${role.id}/g`;
        } else {
          return null;
        }
      })
    );
  }
}
