import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-route',
  templateUrl: './school-route.component.html',
  styleUrls: ['./school-route.component.css']
})
export class SchoolRouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
