import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './user.state';
import { UserRoleState } from './user-role.state';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthState } from './auth.state';
import { DSUtilNgModule } from '../dsutil-ng/dsutil-ng.module';

@NgModule({ declarations: [], imports: [CommonModule,
        NgxsModule.forFeature([
            UserState,
            UserRoleState,
            AuthState,
        ]),
        DSUtilNgModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class PlatformWeb3Module {
}
