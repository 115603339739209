import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DSAInterceptor implements HttpInterceptor {

  static networkLogs = [];

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    let intercept = {
      method: req.method,
      url: req.urlWithParams,
      request: req.body,
      response: null,
      status: null,
    };
    DSAInterceptor.networkLogs.push(intercept);

    if(DSAInterceptor.networkLogs.length > 200) {
      DSAInterceptor.networkLogs.shift();
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            intercept.status = event.status;
            intercept.response = event
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            intercept.status = error.status;
            intercept.response = error
          }
        }
      )
    );
  }
}
