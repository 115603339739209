import { GetAccessToken } from './auth.action';
import { State, StateContext, Action, Selector, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

export interface AuthStateModel {
  /** 取得 AccessToken 的時間。 */
  timestamp?: number;
  /** AccessToken。 */
  accessToken: string;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    timestamp: null,
    accessToken: '',
  }
})
@Injectable()
export class AuthState {

  constructor(
    private auth: AuthService
  ) {}

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  /**
   * 取得 Access Token。
   */
  @Action(GetAccessToken)
  async getAccessToken({getState, patchState}: StateContext<AuthStateModel>, action: GetAccessToken) {
    const sendPeriod = 1000 * 60 * 8; // 8 分鐘內不重新傳送。
    const state = { ...getState() };
    const previousTimestamp = state.timestamp ?? 0;
    const nowTimestamp = Date.now();

    if (((nowTimestamp - previousTimestamp) > sendPeriod) || action.force) {
      const token = await this.auth.getAccessToken(action.force).toPromise();
      state.accessToken = token;
      state.timestamp = Date.now();

      patchState(state);
    }

  }
}
