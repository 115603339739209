import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-content',
  template: `
<div class="p-4">
  <h2 class="mat-dialog-title" id="tServiceLabel">服務條款</h2>
  <mat-dialog-content>
  <p>感謝您使用我們的產品與服務（以下簡稱「服務」）。「服務」是由 ischool Inc.（澔學學習股份有限公司），地址為新竹市自由路 111 號 8 樓之
  1，以下簡稱「ischool」提供。只要您使用「服務」，即表示您同意本條款，故請詳閱本條款內容。</p>
    <ol>
      <li>
        <h3>使用「服務」</h3>
        <p>您必須遵守「服務」中向您提供的所有政策。ischool 所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計、會員內容等，均由 ischool
          或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、公開傳輸、公開演出、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所許可者外，必須依法取得
          ischool 或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對 ischool 負損害賠償責任。</p>
        <p>您僅可於法律允許範圍內使用「服務」，亦不得試圖透過我們所提供的介面和操作說明以外的方法存取「服務」。如果您未遵守我們的條款或政策，或是如果我們正在調查疑似違規行為，我們可能會暫停或終止向您提供「服務」。</p>
        <p>使用「服務」並不會將「服務」或您所存取內容的任何智慧財產權授予您。除非相關內容的擁有者同意或法律允許，否則您一律不得使用「服務」中的內容。</p>
      </li>
      <li>
        <h3>您的 ischool 帳戶</h3>
        <p>您需有「ischool 帳戶」才能使用我們的某些「服務」。您可自行建立「ischool
          帳戶」，依註冊表提示，提供您本人正確、最新及完整的資料，並維持更新您個人資料，確保其為正確、最新及完整。若您提供任何錯誤、不實或不完整的資料，ischool
          有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。</p>
        <p>您也可能由管理人（例如您的雇主或教育機構）為您指派「ischool 帳戶」。如果您使用的是管理人指派給您的「ischool 帳戶」，可能需適用不同條款或附加條款，而且您的管理人可能可以存取或停用您的帳戶。</p>
        <p>您有責任維持密碼及帳戶的機密安全。您並同意以下事項：</p>
        <ol type="a">
          <li>您的密碼或帳戶遭到盜用或有其他任何安全問題發生時，您將立即通知 ischool。</li>
          <li>每次連線完畢，均結束您的帳戶使用。</li>
        </ol>
      </li>
      <li>
        <h3>隱私權與著作權保護</h3>
        <p>ischool 的《隱私權政策》會說明當您使用「服務」時，我們會如何處理您的個人資料並保護您的隱私權。您使用「服務」，即表示您同意 ischool 可依據我們的隱私權政策的規定使用這類資料。</p>
        <p>ischool 尊重他人著作權，亦要求我們的使用者同樣尊重他人著作權，您瞭解並同意我們將依據 ischool
          的「著作權保護政策」保護著作權並處理著作權侵害之事宜。我們會提供相關資訊，協助著作權人管理其智慧財產。如果您認為有人侵犯您的著作權而需要通知我們，可以前往我們的「著作權保護政策」，瞭解如何提出通知，並查詢
          ischool 有關通知回應的政策規定。</p>
      </li>
      <li>
        <h3>您儲存在「服務」中的內容</h3>
        <p>
          「服務」可讓您提交內容；您仍保有相關內容中，您擁有的智慧財產權。也就是屬於您的依舊是您的。若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供予
          ischool。您所上載、傳送、輸入或提供予 ischool 之任何資料，其權利仍為您或您的授權人所有；但任何資料一經您上載、傳送、輸入或提供予 ischool 時，即表示您同意：
        </p>
        <ol type="a">
          <li>由 ischool 及其關係企業儲存或管理該資料，並由 ischool 及其關係企業之搜尋工具進行索引及抓取，並公開刊登、使用於 ischool 及其關係企業的相關系統網路上，包括但不限於
            ischool 所聯盟或合作的第三人網站上。</li>
          <li>授權 ischool 及其關係企業可以基於公益或為宣傳、推廣或經營 ischool
            及本服務之目的，進行使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯該等資料，並得在此範圍內將前述權利轉授權他人。您並保證
            ischool 及其關係企業使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對 ischool
            及其關係企業負損害賠償責任。</li>
        </ol>
      </li>
      <li>
        <h3>會員內容與第三人網站的連結</h3>
        <p>由會員公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料（以下簡稱「會員內容」），均由「會員內容」提供者自負責任。ischool
          無法控制經由本服務而張貼之「會員內容」，因此不保證其正確性、完整性或品質。您了解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之「會員內容」。在任何情況下，ischool
          均不為任何「會員內容」負責，包含但不限於任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害。</p>
        <p>您了解 ischool 並未針對「會員內容」事先加以審查，但 ischool 有權（但無義務）依其自行之考量，拒絕或移除經由本服務提供之任何「會員內容」。在不限制前開規定之前提下，ischool
          及其指定人有權將有違反本服務條款或法令之虞、或令人厭惡之任何「會員內容」加以移除。您使用任何「會員內容」時，就前開「會員內容」之正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。您了解並同意
          ischool 依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將您的帳戶資訊或「會員內容」加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：
        </p>
        <ol type="a">
          <li>遵守法令或政府機關之要求。</li>
          <li>為提供本服務所必須者。</li>
          <li>為防止他人權益之重大危害而有必要者。</li>
          <li>為免除使用者及公眾之生命、身體、自由、權利、財產上之急迫危險者。</li>
        </ol>
        <p>ischool 或協力廠商可能會提供連結至其他網站或網路資源的連結。您可能會因此連結至其他業者經營的網站，但不表示 ischool 與該等業者有任何關係。其他業者經營的網站均由各該業者自行負責，不屬 ischool
          控制及負責範圍之內。ischool 對任何檢索結果或外部連結，不擔保其合適性、可依賴性、即時性、有效性、正確性及完整性。您也許會檢索或連結到一些令您厭惡或不需要的網站，這是網際網路運作的可能結果，遇到此類情形時，ischool
          建議您不要瀏覽或儘速離開該等網站。您並同意 ischool 無須為您連結至非屬於 ischool 之網站所生之任何損害，負損害賠償之責任。</p>
      </li>
      <li>
        <h3>使用者的守法義務及承諾</h3>
        <p>
          您承諾絕不為任何非法目的或以任何非法方式使用「服務」，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用「服務」從事侵害他人權益或違法之行為，包括但不限於：
        </p>
        <ol type="a">
          <li>上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於「服務」上。</li>
          <li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
          <li>違反依法律或契約所應負之保密義務。</li>
          <li>冒用他人名義使用本服務。</li>
          <li>上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料。</li>
          <li>從事不法交易行為或張貼虛假不實、引人犯罪之訊息。</li>
          <li>販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物。</li>
          <li>提供賭博資訊或以任何方式引誘他人參與賭博。</li>
          <li>濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等。</li>
          <li>以任何方法傷害未成年人。</li>
          <li>偽造訊息來源或以任何方式干擾傳輸來源之認定。</li>
          <li>干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等，包括但不限於：使用任何設備、軟體或刻意規避 ischool 之排除自動搜尋之標頭（robot
            exclusion headers）。</li>
          <li>對於恐怖行動提供任何實質支持或資源。</li>
          <li>追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊。</li>
          <li>其他 ischool 有正當理由認為不適當之行為。</li>
        </ol>
      </li>
      <li>
        <h3>買賣或其他交易行為</h3>
        <p>廠商或個人可能透過 ischool 服務連結之其他網站提供商品買賣、服務或其他交易行為。您若因此與該等廠商或個人進行交易，各該買賣或其他合約均僅存在您與各該廠商或個人兩造之間。ischool
          聲明絕不介入您與廠商或個人間的任何買賣、服務或其他交易行為，對於您所獲得的商品、服務或其他交易標的物亦不負任何擔保責任。</p>
        <p>您在「服務」中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各該廣告商、產品與服務的供應商所設計與提出。您對於廣告之正確性與可信度應自行斟酌與判斷。ischool
          僅接受委託予以刊登，不對前述廣告負擔保責任。</p>
      </li>
      <li>
        <h3>有關「服務」所含軟體</h3>
        <p>如果「服務」需有或內含可下載軟體，該軟體可能會在提供新版或新功能時，在您的裝置上自動更新。部分「服務」可以讓您調整您的自動更新設定。ischool 授予您全球通用、免權利金、不得轉讓、非專屬的個人使用授權，讓您使用
          ischool 提供給您的、包含在「服務」中的軟體。本授權僅供您以這些條款允許之方式，使用 ischool
          提供之「服務」並享用其利益。除非法律禁止下列限制，或除非您已取得我們的書面許可，否則您不得複製、修改、散佈、銷售或出租「服務」的任何部分或其中包含的軟體，也不得對該軟體進行逆向工程或試圖擷取其原始程式碼。</p>
        <p>開放原始碼軟體對我們非常重要。我們可能會根據開放原始碼授權（之後會提供給您），向您提供「服務」中使用之部分軟體，而該開放原始碼授權中可能會有些規定的效力明確優先於本條款之部分條款。</p>
      </li>
      <li>
        <h3>修改與終止「服務」</h3>
        <p>我們會持續不斷變更並改進「服務」。我們可能會新增或移除功能或特性，也可能會完全暫停或停止某項「服務」。</p>
        <p>您隨時都可以停止使用「服務」，ischool 也可能隨時停止向您提供「服務」，或對「服務」附加或設定新的限制。</p>
        <p>我們認為您的資料是屬於您所有，因此有必要維護您對這類資料的存取。當我們中止某項「服務」時，只要合理可行，我們都會給您合理的事先通知，讓您有機會能將資訊自該「服務」匯出。</p>
      </li>
      <li>
        <h3>我們的擔保與免責聲明</h3>
        <p>我們會以商業上合理的技術與注意程度提供「服務」，除本條款或額外條款中明示規定者外，ischool
          或其供應商或經銷商均不對「服務」做出任何特定保證。例如，我們不會就「服務」中的內容、「服務」之特定功能及其可靠性、可用性和符合您的需求的能力，做出任何承諾。我們僅以「現狀」提供「服務」。部分司法管轄區會規定應提供特定擔保，例如對適銷性、特殊用途適用性及未侵權之默示擔保。凡法律准許時，我們均排除一切擔保責任。
        </p>
      </li>
      <li>
        <h3>對「服務」的責任</h3>
        <p>凡法律准許時，ischool 與 ischool 的供應商和經銷商對所失利益、收入或資料、財務損失或間接、特殊、衍生性、懲戒性或處罰性損害賠償均不負責。</p>
        <p>在法律准許範圍內，ischool 與其供應商和經銷商對依本條款所提出任何請求之總責任（包括對任何默示擔保之責任），均不超過您就使用「服務」而向 ischool 支付的金額（或者 ischool 亦可選擇再向您提供該「服務」）。
        </p>
        <p>不論在任何情況下，ischool 與其供應商和經銷商對無法合理預見之任何損失或損害均不負責。</p>
      </li>
      <li>
        <h3>公司行號使用「服務」</h3>
        <p>如果您是代表某公司使用「服務」，即表示該公司接受本條款。該公司必須就任何因使用「服務」或違反本條款而引起或相關的請求、訴訟或法律行動（包括承擔所有因請求、損失、損害、訴訟、判決、訴訟費用和律師費用而產生的任何責任或費用），向
          ischool 及其關係企業、主管人員、代理人和員工做出補償，並使其不受損害。</p>
      </li>
      <li>
        <h3>對 ischool 的貢獻</h3>
        <p>若您透過建議或回應網頁對於 ischool 提供任何想法、建議、文件或提議（以下稱「貢獻」），您認知並同意：</p>
        <ol type="a">
          <li>您的貢獻並非特定機密或專有資訊。</li>
          <li>ischool 對前開貢獻並不負有任何明示或默示的保密責任。</li>
          <li>ischool 有權在全球為任何目的、以任何方式、在任何媒體上，使用或揭露（或選擇不使用或揭露）前開貢獻。</li>
          <li>ischool 可能已有與前述貢獻相似而正在考慮或發展中的想法或提案。</li>
          <li>您的貢獻將自動於 ischool 不對您負任何責任的情形下，成為 ischool 之財產。</li>
          <li>您於任何情形下皆無權利對 ischool 主張任何形式的賠償或補償。</li>
        </ol>
      </li>
      <li>
        <h3>有關本條款的說明</h3>
        <p>
          我們可能會修改本條款或適用於某「服務」之任何額外條款，以（例如）反映法律之變更或「服務」之變動。您應定期查閱本條款內容。這些條款如有修訂，我們會在本網頁發佈通知。我們會在相關「服務」中公布已修訂額外條款的通知。變更不會回溯適用，並將於公布變更起十四天或更長時間後方始生效。不過，針對某項「服務」新功能的變更，或基於法律理由而為之變更，將立即生效。如果您不同意某項「服務」之修訂條款，則請停止使用該「服務」。
        </p>
        <p>如果本條款與額外條款牴觸時，就該牴觸項目而言，應以額外條款為準。</p>
        <p>本條款規範 ischool 與您之間的關係，並不會衍生任何第三人受益權。</p>
        <p>如果您不遵守本條款，而我們並未立即採取行動，這並不表示我們要放棄任何原本即有的權利（例如未來再採取行動的權利）。</p>
        <p>發生特定條款無法執行的情況時，並不會影響任何其他條款。</p>
        <p>本服務條款之解釋與適用，以及與本服務條款有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理，並以台灣新竹地方法院為第一審管轄法院。</p>
        <p>如需 ischool 的聯絡資訊，請與
          <a href="mailto:support@ischool.com.tw">我們聯絡</a>。</p>
      </li>
    </ol>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>關閉</button>
  </mat-dialog-actions>
</div>
  `
})
export class ServiceContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
